import calculateShift from "../../utils/expertise-dotted-items/calculate-items-shift.helper";
import styles from "../dotted-item-list/DottedItemList.module.css";
import DottedItem from "../dotted-item/DottedItem";
import React from "react";
import { BrowserView, MobileView } from 'react-device-detect';

const DottedItemListExpertise = ({ dottedItems = [], isPink, activeItemIndex }) => {
    const shift = activeItemIndex || activeItemIndex === 0 ?
        {
            position: 'absolute',
            top: calculateShift(dottedItems, activeItemIndex) + 'px'
        }
        : null;
    return (
        <>
            <BrowserView style={shift}>
                <div className={ styles.itemList }>

                    {
                        dottedItems.map((item, i) => (
                                <DottedItem item={item.title}
                                            key={i}
                                            isPink={isPink}/>
                            )
                        )
                    }
                </div>
            </BrowserView>
            <MobileView className={styles.mobileWrapper}>
                <div className={ styles.itemList }>

                    {
                        dottedItems.map((item, i) => (
                                <DottedItem item={item.title}
                                            key={i}
                                            isPink={isPink}/>
                            )
                        )
                    }
                </div>
            </MobileView>
        </>
    )
};

export default DottedItemListExpertise;
